import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"
import { parseISO, format } from 'date-fns'
import ja from 'date-fns/locale/ja'

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import PublicContributionTab from "../../components/parts/public-contribution-tab"

import SortTable from "../../components/parts/sort-table"
import {DateSort, StringSort} from "../../utils/table-sort"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const publicComment = data.publicComment.edges

  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  const tableHeader = [
    { Header: 'パブリックコメント', accessor: 'title', disableSortBy: true },
    { Header: '案の公示日', accessor: 'planedAt', sortType: StringSort },
    { Header: '受付締切日時', accessor: 'closedAt', sortType: StringSort },
    { Header: '結果公示', accessor: 'resultedAt', sortType: DateSort },
  ]
  
  const tableRows = publicComment.map(item => {
    return {
      title: (
        <span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(item.node.title) }} />
      ),
      planedAt: (
        <>
          { 
            item.node.planedAt !== null && item.node.planedAt.date ?
              item.node.planedAt.link ?
                <a href={item.node.planedAt.link} target="_blank" rel="noreferrer noopener">
                  {format(parseISO(item.node.planedAt.date), 'yyyy年MM月dd日', {locale: ja})}
                </a> :
                format(parseISO(item.node.planedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
                '-'
          }
        </>
      ),
      closedAt: (
        <>
          { 
            item.node.closedAt !== null ? 
              format(parseISO(item.node.closedAt), 'yyyy年MM月dd日 HH時mm分', {locale: ja}) : '-'
          }
        </>
      ),
      resultedAt: (
        <>
          { 
            item.node.resultedAt !== null && item.node.resultedAt.date ? 
              item.node.resultedAt.link ? 
                <a href={item.node.resultedAt.link} target="_blank" rel="noreferrer noopener">
                  {format(parseISO(item.node.resultedAt.date), 'yyyy年MM月dd日', {locale: ja})}
                </a> :
                format(parseISO(item.node.resultedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
                '-'
          }
        </>
      ),
    }
  })

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />
      <BreadCrumb
        parentLink="/about/"
        parentTitle="グリーンイノベーション基金事業概要等"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading}>{title}</h1>
      </div>
      <div className={styles.leadText}>
      </div>

      <main className={styles.pageContents}>
        <PublicContributionTab current="public-comment" />

        <div className={styles.tableWrapper}>
          <SortTable exClass={`${styles.basicTable}`} tableHeader={tableHeader} tableRows={tableRows} summary="パブリックコメント" />
        </div>        
      </main>
      
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
    publicComment: allMicrocmsPublicComment {
      edges {
        node {
          title
          closedAt
          planedAt {
            date
            link
          }
          resultedAt {
            date
            link
          }
        }
      }
    }
  }
`